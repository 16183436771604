import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Hub from "../resources"

export default ({ data }) => (
  <>
    <Helmet>
      <meta property="og:url" content="https://moondisaster.org/resources" />
      <meta
        property="og:title"
        content="In Event of Moon Disaster - Resources"
      />
      <meta
        property="og:description"
        content="A collection of articles, videos, and interactive experiences to help you understand the rise of deepfakes and where they're heading."
      />
      <meta
        property="og:image:secure_url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image:url"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta
        property="og:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:image:alt"
        content="lunar landscape with project logo"
      />
      <meta
        property="article:author:name"
        content="Francesca Panetta and Halsey Burgund"
      />
      <meta
        name="twitter:title"
        content="In Event of Moon Disaster - Resources"
      />
      <meta
        name="twitter:description"
        content="A collection of articles, videos, and interactive experiences to help you understand the rise of deepfakes and where they're heading."
      />
      <meta
        name="twitter:creator"
        content="Francesca Panetta and Halsey Burgund"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x854.jpg?p=1806"
      />
      <meta
        name="twitter:image:alt"
        content="lunar landscape with project logo"
      />
      <meta itemProp="name" content="In Event of Moon Disaster - Resources" />
      <meta
        itemProp="description"
        content="A collection of articles, videos, and interactive experiences to help you understand the rise of deepfakes and where they're heading."
      />
      <meta name="author" content="Francesca Panetta and Halsey Burgund" />
      <meta
        name="description"
        content="A collection of articles, videos, and interactive experiences to help you understand the rise of deepfakes and where they're heading."
      />
      <meta
        name="thumbnail"
        content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"
      />
    </Helmet>
    <Hub data={data.allWpPost.nodes} />
  </>
)

export const query = graphql`
  query {
    allWpPost(
      filter: {
        status: { eq: "publish" }
        categories: { nodes: { elemMatch: { name: { eq: "Articles" } } } }
      }
    ) {
      nodes {
        slug
        path: uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf: postCustomFields {
          index
          mediaType
        }
      }
    }
  }
`
